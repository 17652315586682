// AnnonceCard.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './AnnonceCard.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Timestamp } from 'firebase/firestore';

function AnnonceCard({ vehicle, onVehicleClick}) {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      const storage = getStorage();
      // get only the first image
      const imageFolderRef = ref(storage, `VOITURES/${vehicle.id}`);

      // get only the first image of the folder
     // const imageFolderRef = ref(storage, `VOITURES/${vehicle.id}`);

      try {
        /*const images = await listAll(imageFolderRef);
        const urlPromises = images.items.map((imageRef) => getDownloadURL(imageRef));
        const urls = await Promise.all(urlPromises);
        setImageUrls(urls);*/
        const images = await listAll(imageFolderRef);
        const url = await getDownloadURL(images.items[0]);
        setImageUrls([url]);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }

    if (vehicle) {
      fetchImages();
    }
  }, [vehicle]);

  if (!vehicle) {
    // Handle the case where the vehicle is not defined
    return null; // or some default content
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  const getLivraisonText = () => {
    if (vehicle.livraison) {
      return (
        <div className="livraison">
          <img className='imglivraison' src='livraison.svg' alt='picto_livraison'/>
          <p className='plivraison'>Livraison</p>
        </div>
      );
    }
    return null;
  }
  const getGarantieText = () => {
    if (vehicle.garantieMois > 0) {
      return (
        <div className="garantie">
          <img className='imggarantie' src='garantie.svg' alt='picto_garantie'/>
          <p className='pgarantie'>{`Garantie ${vehicle.garantieMois} mois`}</p>
        </div>
      );
    }
    return null;
  }

  const date = vehicle.MiseEnCiruclation.toDate();
  var month = date.getMonth();
  const year = date.getFullYear();
  const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
  "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];
  month = monthNames[month];

  if (vehicle.vendu === true) {
    return (
      <div className="annonce-card-vendu">
        <Slider {...settings} className='DivSlider' >
          {imageUrls.map((url, index) => (
            <div key={index} >
              <img className="slider-image" src={url} alt={`vehicle ${index + 1}`} />
            </div>
          ))}
        </Slider>
        <div className="vehicle-info">
        <h3 className="vehicle-title">{`${vehicle.marque} ${vehicle.modele}`}</h3>
          <p className="vehicle-detailsPrecis">{vehicle.modelePrecis}</p>
          <p className="vehicle-details ">{`${month} ${year} | ${vehicle.kilometrage.toLocaleString()} km | ${vehicle.carburant} | ${vehicle.boiteVitesse} `}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="annonce-card" onClick={onVehicleClick}>
      <Slider {...settings} className='DivSlider' >
        {imageUrls.map((url, index) => (
          <div key={index} >
            <img className="slider-image" src={url} alt={`vehicle ${index + 1}`} />
          </div>
        ))}
      </Slider>
      <div className="vehicle-info">
      <h3 className="vehicle-title">{`${vehicle.marque} ${vehicle.modele}`}</h3>
        <p className="vehicle-detailsPrecis">{vehicle.modelePrecis}</p>
        <p className="vehicle-details ">{`${month} ${year} | ${vehicle.kilometrage.toLocaleString()} km | ${vehicle.carburant} | ${vehicle.boiteVitesse} `}</p>
        <hr className="bar-separation"/>
        <p className="vehicle-price spaced">{`${vehicle.prix.toLocaleString()} €`}</p>
        <div className="livraisonGarantie">
        {getGarantieText()} {/* Nouveau composant pour la garantie */}
        {getLivraisonText()} {/* Nouveau composant pour la livraison */}
        </div>
        <hr className="bar-separation"/>
        <p className="vehicle-localisation">{`📍 ${vehicle.localisation}`}</p>
      </div>
    </div>
  );
}

// Composants pour les flèches du slider
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      &lt; Précédent
    </div>
  );
}
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      Suivant &gt;
    </div>
  );
}


export default AnnonceCard;